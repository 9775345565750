/* eslint-disable import/no-anonymous-default-export */

import colors from './palette';

export default {
  fontFamily: 'Mina, sans-serif',
  useNextVariants: true,
  h1: {
    color: colors.primary,
    fontWeight: 'bold',
    fontSize: '2.5rem',
    lineHeight: 1.2,
  },
  h2: {
    color: '#4F4F4F',
    fontSize: '2rem',
    lineHeight: 1.3,
  },
  h3: {
    color: '#4F4F4F',
    fontSize: '1.7rem',
    lineHeight: 1.3,
  },
  h4: {
    color: '#4F4F4F',
    fontSize: '1.5rem',
    lineHeight: 1.3,
  },
  h5: {
    color: '#4F4F4F',
    fontSize: '1.2rem',
    lineHeight: 1.3,
    textTransform: 'uppercase',
  },
  h6: {
    color: '#4F4F4F',
    fontSize: '1rem',
    lineHeight: 1.1,
  },
  body1: {
    fontSize: '0.8rem',
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: 1.2,
  },
  caption: {
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '14px',
  },
};

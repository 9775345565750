import React from 'react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import { mainListItems, secondaryListItems } from './listItems';
import { DashboardService } from 'core/api/services/dashboard';
import DailyChart from './DailyChart';
import TotalChart from './TotalChart';
import Deposits from './Deposits';
import Orders from './Orders';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [todayData, setTodayData] = useState(undefined);
  const [recentDeposits, setRecentDeposits] = useState([]);
  const [recentOrders, setRecentOrders] = useState([]);
  const [totalCreatedBookings, setTotalCreatedBookings] = useState(0);
  const [totalCreatedTickets, setTotalCreatedTickets] = useState(0);
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [loading, setLoading] = useState(false)

  const getAllDashboard = async () => {
    try {
      setLoading(true)
        await DashboardService.get().then(data => {
        if(data.todayData) { setTodayData(data.todayData); }
        if(data.recentDeposits) { setRecentDeposits(data.recentDeposits); }
        if(data.recentOrders) { setRecentOrders(data.recentOrders); }
        if(data.totalCreatedBookings) { setTotalCreatedBookings(data.totalCreatedBookings); }
        if(data.totalCreatedTickets) { setTotalCreatedTickets(data.totalCreatedTickets); }
        if(data.totalBookings) { setTotalBookings(data.totalBookings); }
        if(data.totalTickets) { setTotalTickets(data.totalTickets); }
      });
    } catch (error) {
      console.error(error)
      return false;
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllDashboard()
  }, []);

  return (
    <>
    {loading ? (
      <CircularIndeterminate/>
    ) : (
        <div className={classes.root}>
          <CssBaseline />
          <main className={classes.content}>
            {/* <div className={classes.appBarSpacer} /> */}
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
                {/* Charts */}
                <Grid item xs={12} md={6} lg={6}>
                  <Paper className={fixedHeightPaper}>
                    <TotalChart todayData={todayData} totalBookings={totalBookings} totalTickets={totalTickets} />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Paper className={fixedHeightPaper}>
                    <TotalChart created={true} name='Total Created Tickets' todayData={todayData} totalBookings={totalCreatedBookings} totalTickets={totalCreatedTickets} />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <Paper className={fixedHeightPaper}>
                    <DailyChart todayData={todayData} />
                  </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                  <Paper className={fixedHeightPaper}>
                    <Deposits recentDeposits={recentDeposits} />
                  </Paper>
                </Grid>
                {/* Recent Orders */}
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Orders recentOrders={recentOrders} />
                  </Paper>
                </Grid>
              </Grid>
              <Box pt={4}>
                {/* <Copyright /> */}
              </Box>
            </Container>
          </main>
        </div>
      )}
        </>
  );
}
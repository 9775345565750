import { fetch, Method, getPaging } from 'core/api/fetch';
import {
    parseMarkupToModel,
    parseMarkupToObject,
} from 'core/api/parser/markups';

const MarkupsRoutes = {
    get: (id) => `/api/v1.1.0/admin/markup/${id}`,
    getList: (options) => `/api/v1.1.0/admin/markup?${getPaging(options)}`,
    add: '/api/v1.1.0/admin/markup',
    update: (id) => `/api/v1.1.0/admin/markup/${id}`,
};

export const MarkupService = {
    get: async (id) => {
        try {
            const { data } = await fetch(MarkupsRoutes.get(id), {
                method: Method.GET,
                authenticated: true,
            });
            return !data || !data.length ? {} : parseMarkupToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },

    getList: async (options) => {
        try {
            const { data } = await fetch(MarkupsRoutes.getList(options), {
                method: Method.GET,
                authenticated: true,
            });
            return !data
                ? { markups: [], total: 0 }
                : {
                      total: data.total,
                      markups: data.markups.map(parseMarkupToModel),
                  };
        } catch (err) {
            throw new Error(err);
        }
    },

    add: async (info) => {
        try {
            const { data } = await fetch(MarkupsRoutes.add, {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.status ? false : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(MarkupsRoutes.update(info.id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
};

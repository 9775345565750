import { fetch, Method, getPaging } from 'core/api/fetch';

const DashboardRoutes = {
    get: () => `/api/v1.1.0/admin/dashboard`,
};

export const DashboardService = {

    get: async () => {
        try {
            const { data } = await fetch(DashboardRoutes.get(), {
                method: Method.GET,
                authenticated: true
            });
            return data;
        } catch (err) {
            throw new Error(err);
        }
    },
};

import React, { useContext, useState } from 'react';
import { BundleService } from 'core/api/services/bundle';

const BundleContext = React.createContext(null);

export const BundleProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [bundle, setBundle] = useState([]);
  const [bundleExperiences, setBundleExperiences] = useState([]);
  const [total, setTotal] = useState([]);
  const [totalExperiences, setTotalExperiences] = useState([]);

  const getBundles = async (options) => {
    try {
      
      setLoading(true);
      const result = await BundleService.getList(options);

      if (!result) {
        throw new Error();
      }
      setBundles(result.bundles);
      setTotal(result.total)
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getBundleExperience = async (id,options) => {
    try {
      
      setLoading(true);
      const result = await BundleService.getExperienceList(id,options);

      if (!result) {
        throw new Error();
      }
      setBundleExperiences(result.experiences);
      setTotalExperiences(result.total);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getBundle = async (id) => {
    try {
      
      setLoading(true);
      const result = await BundleService.get(id);
      if (!result) {
        throw new Error();
      }
      setBundle(result);
      setTotalExperiences(result.experiences?.total);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const addBundle = async (values) => {
    try {
      setLoading(true);
      const {bundle} = await BundleService.add(values);
      if (!bundle) {
        throw new Error();
      }
      setBundle(bundle);
      return bundle;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateBundle = async (values) => {
    try {
      setLoading(true);
      const result = await BundleService.update(values);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const addBundleExperiences = async (id, values) => {
    try {
      setLoading(true);
      const result = await BundleService.addExperiences(id, values);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateBundleExperience = async (id, expId, values) => {
    try {
      setLoading(true);
      const result = await BundleService.updateExperience(id, expId, values);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const deleteBundleExperiences = async (id, expId) => {
    try {
      setLoading(true);
      const result = await BundleService.deleteExperiences(id, expId);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <BundleContext.Provider
      value={{
          loading,
          total,
          totalExperiences,
          bundle,
          bundleExperiences,
          bundles,
          setBundle,
          getBundle,
          getBundleExperience,
          getBundles,
          addBundle,
          updateBundle,
          addBundleExperiences,
          deleteBundleExperiences,
          updateBundleExperience,
      }}>
      {children}
    </BundleContext.Provider>
  );
};

BundleProvider.displayName = 'BundleProvider';

export const useBundle = () => useContext(BundleContext);

export const parseBookingToModel = (booking) => ({
    // hasQR: contract.isQR ? 'Yes' : 'No',
    ...booking,
  });
  
  export const parseBookingToObject = (data) => ({
    // code: data.id.toString(16),
    // qrURL: data.isQR ? `/static/contracts/${data.id}/qr.png` : null,
    // hasQR: data.isQR ? 'Yes' : 'No',
    ...data,
  });

  export const parseBookingLogsToModel = (bookingLog) => ({
    ...bookingLog,
  });
  
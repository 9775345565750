import Title from 'core/libs/core-ui/components/Title';

import Dashboard from './Dashboard';

const AdminHomePage = () => {


  return (
    <>
      <Title value={'Admin Panel'} />
      <Dashboard />
    </>
  );
};

AdminHomePage.displayName = 'AdminHomePage';

export default AdminHomePage;

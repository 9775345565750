import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseResourceToModel, parseResourceToObject } from 'core/api/parser/resource';

const ResourceRoutes = {
    get: (id) => `/api/v1.1.0/admin/resource/${id}`,
    getList: (options) => `/api/v1.1.0/admin/resource?name=${options.nameSearch}&email=${options.emailSearch}&experiencerId=${options.experiencerIdSearch}&${getPaging(options)}`,
    add: '/api/v1.1.0/admin/resource',
    update: (id) => `/api/v1.1.0/admin/resource/${id}`,
};

export const ResourceService = {

    get: async (id) => {
        try {
            const { data } = await fetch(ResourceRoutes.get(id), {
                method: Method.GET,
                authenticated: true
            });
            return !data || !data.length ? {} : parseResourceToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },
    getList: async (options) => {
        try {
            const { data } = await fetch(ResourceRoutes.getList(options), {
                method: Method.GET,
                authenticated: true
            });

            return !data ? {resources:[],total:0} : {total: data.total, resources: data.resources.map(parseResourceToModel)};
        } catch (err) {
            throw new Error(err);
        }
    },

    add: async (info) => {
        try {
            const { data } = await fetch(ResourceRoutes.add, {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.status ? false : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(ResourceRoutes.update(info.id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    uploadResourcePicture: async (id, image) => {
        try {
            const formData = new FormData();
            image =
                image.type && image.type !== ''
                    ? image
                    : image.slice(0, image.size, 'image/png');
            formData.append('images', image, image.name ?? `resource_${id}.png`);
            await fetch(ResourceRoutes.uploadImage(id), {
                method: Method.POST,
                authenticated: true,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (err) {
            throw new Error(err);
        }
    },

};

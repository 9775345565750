import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  text: {
    marginTop: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  formContainer: {
    padding: '10px',
  },
  formTitle: {
    marginBottom: theme.spacing(2),
  },
  formFormControl: {
    marginBottom: theme.spacing(3),
  },
  divEditButton: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    gap: '30px !important',
  },
  editButton: {
    cursor: 'pointer !important',
    border: 'none !important',
    backgroundColor: '#71ba6c !important',

    color: '#ffffff !important',
    lineHeight: '40px !important',
    height: '40px !important',
    textAlign: 'center !important',
    fontSize: '14px !important',
    borderRadius: '4px !important',
    border: 'none !important',
    padding: '0 20px !important',
    cursor: 'pointer !important',
    display: 'flex',
    alignItems: 'center',
    gridGap: '15px',
    gap: '15px',
  },

}));

import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Login from './components/Login';
import { useStyles } from './styles';

// import SignUp from './components/SignUp';
import { useAuth } from 'core/contexts/auth';
import { ROUTES } from 'core/routing/routes';
import logo from 'assets/images/utriper-black-logo.png';
import LanguageDropDown from 'components/LanguageDropDown';

const LandingPage = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.main}>
      {/* <img src={logo} className={classes.logo} /> */}
      <div className={classes.container}>
        <div className={classes.containerWrapper}>
          <div className={classes.imageContainer}></div>
        </div>
        {/* <div className={classes.languageContainer}>
          <LanguageDropDown style={{ marginRight: 0, marginLeft: 'auto' }} />
        </div> */}
        {/* <Grid container> */}
          {/* <Grid item xs={12} md={16} className={classes.itemContainer}> */}
            <Login />
          {/* </Grid> */}
          {/* <Grid item xs={12} md={6} className={classes.itemContainer}>
            {/* <SignUp /> */}
          {/* </Grid>  */}
        {/* </Grid> */}
      </div>
    </div>
  );
};

LandingPage.displayName = 'LandingPage';

export default LandingPage;

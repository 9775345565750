import Layout from 'components/Layout';
import { useAuth } from 'core/contexts/auth';
// import TriperHomePage from 'modules/Home/Triper';
import AdminHomePage from 'modules/Home/Admin';

import { useStyles } from './styles';

const HomePage = () => {
  const { role } = useAuth();

  const classes = useStyles();

  return (
    <Layout contentClassName={classes.content} isHome={true}>
      {/* {role === 'triper' && <TriperHomePage />} */}
      {role === 'admin' && <AdminHomePage />}
    </Layout>
  );
};

HomePage.displayName = 'HomePage';

export default HomePage;

import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseExperienceToModel, parseExperienceToObject, parseExperienceScheduleToModel } from 'core/api/parser/experience';

const ExperienceRoutes = {
    getList: (options) =>
        `/api/v1.1.0/admin/experience?name=${options.nameSearch}${
            options.experienceIdSearch
                ? '&experienceId=' + options.experienceIdSearch
                : ''
        }&experiencerId=${options.experiencerIdSearch}&${getPaging(options)}`,
    get: (id) => `/api/v1.1.0/admin/experience/${id}`,
    update: (id) => `/api/v1.1.0/admin/experience/${id}`,
    getExperienceSchedule: ({id, range}) => `/api/v1.1.0/admin/experience/${id}/ExperienceSchedule?start=${range?.start?.toISOString()}&end=${range.end?.toISOString()}`,
    getExperienceScheduleId: ({id, schId}) => `/api/v1.1.0/admin/experience/${id}/ExperienceSchedule/${schId}`,
    addExperienceSchedule: ({experienceDetailId}) => `/api/v1.1.0/admin/experience/${experienceDetailId}/ExperienceSchedule`,
    updateExperienceSchedule: ({experienceDetailId, experienceScheduleId, all}) => `/api/v1.1.0/admin/experience/${experienceDetailId}/ExperienceSchedule/${experienceScheduleId}?all=${all}`,
    deleteExperienceSchedule: ({experienceDetailId, experienceScheduleId, all}) => `/api/v1.1.0/admin/experience/${experienceDetailId}/ExperienceSchedule/${experienceScheduleId}?all=${all}`,
};

export const ExperienceService = {
    getExperiences: async (options) => {
        try {
            const { data } = await fetch(ExperienceRoutes.getList(options), {
                method: Method.GET,
                authenticated: true,
            });
            return !data
                ? { experiences: [], total: 0 }
                : {
                      total: data.total,
                      experiences: data.experiences.map(parseExperienceToModel),
                  };
        } catch (err) {
            throw new Error(err);
        }
    },

    get: async (options) => {
        try {
            const { data } = await fetch(ExperienceRoutes.get(options), {
                method: Method.GET,
                authenticated: true,
            });
            //   return !data || !data.length ? [] : data.map(parseExperiencerToModel);
            return !data || !data.length
                ? {}
                : parseExperienceToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(ExperienceRoutes.update(info.id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    getExperienceSchedule: async (options) => {
        try {
            const { data } = await fetch(ExperienceRoutes.getExperienceSchedule(options), {
                method: Method.GET,
                authenticated: true,
            });
              return !data || !data.length ? [] : data.map(parseExperienceScheduleToModel);
        } catch (err) {
            throw new Error(err);
        }
    },

    // getExperienceScheduleId: async (options) => {
    //     try {
    //         const { data } = await fetch(ExperienceRoutes.getExperienceScheduleId(options), {
    //             method: Method.GET,
    //             authenticated: true,
    //         });
    //           return !data || !data.length ? [] : data.map(parseExperienceScheduleToModel);
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },

    addExperienceSchedule: async (info) => {
        try {
            const { data } = await fetch(ExperienceRoutes.addExperienceSchedule(info), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateExperienceSchedule: async (info) => {
        try {
            const { data } = await fetch(ExperienceRoutes.updateExperienceSchedule(info), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    deleteExperienceSchedule: async (info) => {
        try {
            const { data } = await fetch(ExperienceRoutes.deleteExperienceSchedule(info), {
                method: Method.DELETE,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    content: {
        padding: '20px 30px 10px !important',
        maxWidth: '650px'
    },
    marginRow: {
        marginTop: '10px !important',
    },
    title: {
        fontSize: '22px !important',
        lineHeight: '42px !important',
        fontWeight: 'bold !important',
        color: '#4f4f4f !important',
    },
    thisWeek: {
        textTransform: 'inherit',
        margin: '10px 0 !important',
        color: '#fff !important',
        background: '#5fb6d9 !important',
        width: '100%',
    },
    everyWeek: {
        textTransform: 'inherit',
        color: '#fff !important',
        background: '#b8becd !important',
        width: '100%',
    },

    bgOrange300: {
        backgroundColor: '#FB8F1D !important',
    },

    bgBlue300: {
        backgroundColor: '#5fb6d9 !important',
        '&:hover': {
            backgroundColor: '#498BA6 !important',
        },
    },

    capacity: {
        // display: 'flex !important',
        // fontWeight: 'bold',
        // fontSize: '14px',
        // lineHeight: '24px',
        // gap: '10px',
        // marginBottom: '20px !important',
        // alignItems: 'center !important',
    },
}));

import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Deposits({recentDeposits, ...props}) {
  const classes = useStyles();
  const lastDeposit = recentDeposits && recentDeposits.length ? recentDeposits[0] : {};
  return (
    <React.Fragment>
      <Title>Recent Deposits</Title>
      <Typography component="p" variant="h4">
      COP {MoneyFormattedUtils(lastDeposit.totalPaidPrice)}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        on {DateFormattedUtils(lastDeposit.createdOn)}
      </Typography>
      <div>
        <Link color="primary" href="/payment">
          View payments
        </Link>
      </div>
    </React.Fragment>
  );
}
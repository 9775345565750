
const Title = ({ value, classes, styles, size }) => {
    switch (size) {
        case 'l':
            return <h2 className={classes} style={styles}>{value}</h2>;
        case 'm':
            return <h3 className={classes} style={styles}>{value}</h3>;
        case 's':
            return <h4 className={classes} style={styles}>{value}</h4>;
        case 'xs':
            return <h6 className={classes} style={styles}>{value}</h6>;
        default:
            return <h1 className={classes} style={styles}>{value}</h1>;
    }
};

Title.displayName = 'Title';

export default Title;
import React, { useContext, useState } from 'react';
import { PaymentService } from 'core/api/services/payment';

const PaymentContext = React.createContext(null);

export const PaymentProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [payment, setPayment] = useState([]);
  // const [contractExperiences, setContractExperiences] = useState([]);
  const [total, setTotal] = useState([]);
  // const [totalExperiences, setTotalExperiences] = useState([]);

  const getPayments = async (options) => {
    try {
      
      setLoading(true);
      const result = await PaymentService.getList(options);

      if (!result) {
        throw new Error();
      }
      setPayments(result.payments);
      setTotal(result.total)
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // const getContractExperience = async (id,options) => {
  //   try {
      
  //     setLoading(true);
  //     const result = await ContractService.getExperienceList(id,options);

  //     if (!result) {
  //       throw new Error();
  //     }
  //     setContractExperiences(result.experiences);
  //     setTotalExperiences(result.total);
  //     return true;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getPayment = async (id) => {
    try {
      
      setLoading(true);
      const result = await PaymentService.get(id);
      if (!result) {
        throw new Error();
      }
      setPayment(result);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const addPayment = async (values) => {
    try {
      setLoading(true);
      const {payment} = await PaymentService.add(values);
      if (!payment) {
        throw new Error();
      }
      setPayment(payment);
      return payment;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updatePayment = async (values) => {
    try {
      setLoading(true);
      const result = await PaymentService.update(values);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // const updateContractExperiences = async (id, values) => {
  //   try {
  //     setLoading(true);
  //     const result = await ContractService.updateExperiences(id, values);
  //     if (!result) {
  //       throw new Error();
  //     }
  //     return result;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const deleteContractExperiences = async (id, expId) => {
  //   try {
  //     setLoading(true);
  //     const result = await ContractService.deleteExperiences(id, expId);
  //     if (!result) {
  //       throw new Error();
  //     }
  //     return result;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <PaymentContext.Provider
      value={{
          loading,
          total,
          payment,
          setPayment,
          payments,
          getPayment,
          getPayments,
          // addPayment,
          updatePayment,
      }}>
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.displayName = 'PaymentProvider';

export const usePayment = () => useContext(PaymentContext);

import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gridPadre: {
      [theme.breakpoints.up('sm')]: {},
      display: 'flex',
      alignContent: 'center',
      flexDirection: 'column',
      minWidth: '312px',
      position: 'relative',
  },
  loaderContainer: {
      top: '0',
      position: 'absolute',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: '99999999999999999999',
      background: '#f8f8f8',
      opacity: '.8',
  },
}));

export default useStyles;


export const parseHostToModel = (host) => ({
    // hasQR: contract.isQR ? 'Yes' : 'No',
    ...host,
  });
  
  export const parseHostToObject = (data) => ({
    // code: data.id.toString(16),
    // qrURL: data.isQR ? `/static/contracts/${data.id}/qr.png` : null,
    // hasQR: data.isQR ? 'Yes' : 'No',
    ...data,
  });

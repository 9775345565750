import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    availablePaper: {
        backgroundColor: '#12a14b',
        color: 'white',
        cursor: 'pointer',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    disabledPaper: {
        backgroundColor: 'grey',
        color: 'white',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    selectedPaper: {
        backgroundColor: '#9bc2d1',
        color: 'white',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    slotContainer: {
        margin: 'auto',
    },
    gridFather: {
        display: 'flex',
        flexDirection: 'column',
    },
    paperItem: {},
    slotGrid: {},
}));

export default useStyles;

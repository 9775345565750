import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    height: '100vh',
    // width: '100vw',
    flexGrow: 1,
    // marginTop: 56,
    // marginLeft: 60,
    // marginRight: 60,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginLeft: 65,
      marginRight:5,
      // marginRight: 65,
    },
    padding: '0 0 0 60px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: (props) => props.drawerWidth,
    maxHeight: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
  },
}));

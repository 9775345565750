import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { Typography, Grid, useMediaQuery, Divider, Button, TextField } from '@material-ui/core';

import EditIcon from 'assets/icons/edit-white.svg';

import Layout from 'components/Layout';

import Title from 'core/libs/core-ui/components/Title';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import { useTranslation } from 'core/contexts/localization';
import { useDiscount } from 'core/contexts/discounts';

import { useStyles } from './styles';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';

const DiscountPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { t: translate } = useTranslation();

  const {getDiscount, loading, addDiscount, updateDiscount, discount, setDiscount} = useDiscount()

  const [discountChanged, setDiscountChanged] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [discountInfo, setDiscountInfo] = useState({});
  const [discountData, setDiscountData] = useState({});

  const locationParam = document.location.href.split('/');
  const discountIndex = locationParam[locationParam.length - 1] ? parseInt(locationParam[locationParam.length - 1]) : false;

  const onSubmit = async (values) => {
    const { status, discountRate } = values
    if (!values) return;
    try {
      if (discountIndex == 0) {
        const valuesClon = { ...values };
        if (!status) { valuesClon.status = 1 }
        if (!discountChanged || !discountRate) {
          toast.error("Por favor Ingresar al menos un descuento", {position: 'top-right', theme: 'colored'})
          throw new Error("")
        } else {
          const newDiscount = await addDiscount(valuesClon);
          setCanEdit(false);
          getDiscount(newDiscount.id);
          history.push(`/discount/${newDiscount.id}`);
        }
      } else {
        const valuesClon = { ...values };
        if (!discountChanged || !discountRate) {
          toast.error("Por favor Ingresar al menos un descuento", {position: 'top-right', theme: 'colored'})
          throw new Error("")
        }
        else {
          await updateDiscount(valuesClon);
          setCanEdit(false);
          getDiscount(discountIndex);
        }
      }
    } catch (err) {
      toast.error(err, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    setDiscount({});
    if (discountIndex == 0) {
      setCanEdit(true);
    } else {
     getDiscount(discountIndex);
    }
  }, []);

    useEffect(() => {
    setDiscountData(discount);
    }, [discount]);
  

  return (
    <Layout contentClassName={classes.content}>
      <Title value={'Discount'} />
      <div>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <Grid>
            <div className={classes.formContainer}>
              <Title value={discount?.name} size={'l'} />
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  ...discountData,
                }}
                render={({ handleSubmit, form }) => (
                  <form id="form" onSubmit={handleSubmit}>
                    <div>
                      {discountIndex !== 0 ? (
                        <>
                         <div className={classes.divEditButton}>
                        <Typography
                          style={{ fontWeight: 'bold', marginBottom: '10px' }}
                          variant="h5"
                          className={classes.text}>
                          {"Edit Discount Data"}
                        </Typography>
                        <Button
                          type="button"
                          onClick={() => {
                            setCanEdit(true);
                          }}
                          style={{ height: 40, margin: 'inherit' }}
                          className={classes.editButton}>
                          <img src={EditIcon} width={20} />
                          {isSmall ? null : <>&nbsp;</>}
                          {isSmall ? null : `Edit`}
                        </Button>
                      </div>

                      <Divider /></>
                      ) : (
                        <Typography
                          style={{ fontWeight: 'bold', marginBottom: '10px' }}
                          variant="h5"
                          className={classes.text}>
                          Crea el descuento:
                        </Typography>
                     )}

                      <div style={{ marginTop: '20px' }}>
                        <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Field
                              id="id"
                              name="id"
                              component={TextInput}
                              hintText={"Id"}
                              label={"Id"}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              onFieldChange={(value) => {
                                discountInfo.start = value;
                                setDiscountInfo(discountInfo);
                                setDiscountChanged(true);
                              }}
                              name="start"
                              id="start"
                              component={TextInput}
                              hintText={"Start"}
                              label={"Start"}
                              disabled={!canEdit}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Field
                              onFieldChange={(value) => {
                                discountInfo.additional = value;
                                setDiscountInfo(discountInfo);
                                setDiscountChanged(true);
                              }}
                              id="additional"
                              name="additional"
                              component={TextInput}
                              hintText={'additional'}
                              label={'additional'}
                              disabled={!canEdit}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              onFieldChange={(value) => {
                                discountInfo.end = value;
                                setDiscountInfo(discountInfo);
                                setDiscountChanged(true);
                              }}
                              name="end"
                              id="end"
                              component={TextInput}
                              hintText={"End"}
                              label={"End"}
                              disabled={!canEdit}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                          <Grid item xs={12} sm={6} >
                            <Field
                              onFieldChange={(value) => {
                                discountInfo.createdBy = value;
                                setDiscountInfo(discountInfo);
                                setDiscountChanged(true);
                              }}
                              id="createdBy"
                              name="createdBy"
                              component={TextInput}
                              hintText={"createdBy"}
                              label={"createdBy"}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Field
                              onFieldChange={(value) => {
                                discountInfo.createdOn = value;
                                setDiscountInfo(discountInfo);
                                setDiscountChanged(true);
                              }}
                              id="createdOn"
                              name="createdOn"
                              component={TextInput}
                              hintText={"createdOn"}
                              label={"createdOn"}
                              disabled
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                          <Grid item xs={12} sm={6} >
                            <Field
                              onFieldChange={(value) => {
                                discountInfo.updatedBy = value;
                                setDiscountInfo(discountInfo);
                                setDiscountChanged(true);
                              }}
                              id="updatedBy"
                              name="updatedBy"
                              component={TextInput}
                              hintText={"updatedBy"}
                              label={"updatedBy"}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Field
                              onFieldChange={(value) => {
                                discountInfo.updatedOn = value;
                                setDiscountInfo(discountInfo);
                                setDiscountChanged(true);
                              }}
                              id="updatedOn"
                              name="updatedOn"
                              component={TextInput}
                              hintText={"updatedOn"}
                              label={"updatedOn"}
                              disabled
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                          <Grid item xs={12} sm={6} container>

                          <Grid item xs={12} sm={6} >
                            <Field
                              id="status"
                              name="status"
                              render={({ input, meta }) => (
                                <SelectComponent
                                    {...input}
                                    values={[{ name: "0", id: "0" }, { name: "1", id: "1" }]}
                                    label={"Status (no usar)"}
                                    labelId={"status-label"}
                                    hasError={meta.touched && !!meta.error}
                                    errorMessage={meta.error}
                                    onFieldChange={(event) => {
                                      input.onChange(event);
                                      const value = event.target.value;
                                      discountInfo.status = value
                                      setDiscountInfo({ ...discountInfo })
                                      setDiscountChanged(true)
                                    }}
                                    disabled={!canEdit}
                                  />
                              )}
                              />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Field
                              id="discountRate"
                              name="discountRate"
                              >
                                {({input}) => (
                                  <TextField
                                  {...input}
                                  type='number'
                                  variant='outlined'
                                  hintText={"discountRate"}
                                  label={"discountRate"}
                                  disabled={!canEdit}
                                  onChange={(e) => {
                                    let value = e.target.value < 0 ? 0 : e.target.value > 100 ? 100 : e.target.value
                                    discountInfo.discountRate = value
                                    setDiscountInfo(discountInfo);
                                    setDiscountChanged(true);
                                    input.onChange(value)
                                  }}
                                  />
                                )}
                              </Field> 
                          </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              onFieldChange={(value) => {
                                discountInfo.cancelReason = value
                                setDiscountInfo(discountInfo)
                                setDiscountChanged(true)
                              }}
                              id="cancelReason"
                              name="cancelReason"
                              component={TextInput}
                              hintText={"cancelReason"}
                              label={"cancelReason"}
                              disabled={!canEdit || discountIndex == 0}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <Grid item container justify="flex-end" alignContent="flex-end">
                      <Grid
                        item
                        container
                        justify={'space-between'}
                        style={{ width: 'auto' }}>
                        <Button
                          className="rounded mr-3 height-40 w-100px"
                          disabled={!canEdit}
                          type="button"
                          onClick={(e) => {
                            setCanEdit(false);
                            return form.reset(e);
                          }}>
                          {translate('btn_cancel')}
                        </Button>

                        <Button
                          onClick={(e) => {
                            return handleSubmit(e);
                          }}
                          className="rounded height-40 w-100px triper-button bg-green-300"
                          disabled={!canEdit}
                          type="submit">
                          {translate('btn_submit')}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </div>
          </Grid>
        )
        }
      </div>
    </Layout>
  );
};

DiscountPage.displayName = 'DiscountPage';

export default DiscountPage;

import React, { useContext, useState } from 'react';
import { BookingService } from 'core/api/services/booking';

const BookingContext = React.createContext(null);

export const BookingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [booking, setBooking] = useState([]);
  // const [contractExperiences, setContractExperiences] = useState([]);
  const [total, setTotal] = useState([]);
  // const [totalExperiences, setTotalExperiences] = useState([]);
  const [bookingLogs, setBookingLogs] = useState([]);
  const [totalBookingLogs, setTotalBookingLogs] = useState([]);
  const [loadingBookingLogs, setLoadingBookingLogs] = useState(false);

  

  const getBookings = async (options) => {
    try {
      
      setLoading(true);
      const result = await BookingService.getList(options);

      if (!result) {
        throw new Error();
      }
      setBookings(result.bookings);
      setTotal(result.total)
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // const getContractExperience = async (id,options) => {
  //   try {
      
  //     setLoading(true);
  //     const result = await ContractService.getExperienceList(id,options);

  //     if (!result) {
  //       throw new Error();
  //     }
  //     setContractExperiences(result.experiences);
  //     setTotalExperiences(result.total);
  //     return true;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getBooking = async (id) => {
    try {
      
      setLoading(true);
      const result = await BookingService.get(id);
      if (!result) {
        throw new Error();
      }
      setBooking(result);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };
  const getBookingLogs = async (id, options) => {
    try {
      
      setLoadingBookingLogs(true);
      const result = await BookingService.getBookingLogs(id, options);
      if (!result) {
        throw new Error();
      }
      setBookingLogs(result.bookingLogs);
      setTotalBookingLogs(result.total)
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoadingBookingLogs(false);
    }
  };

  const addBooking = async (values) => {
    try {
      setLoading(true);
      const {booking} = await BookingService.add(values);
      if (!booking) {
        throw new Error();
      }
      setBooking(booking);
      return booking;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateBooking = async (values) => {
    try {
      setLoading(true);
      const result = await BookingService.update(values);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // const updateContractExperiences = async (id, values) => {
  //   try {
  //     setLoading(true);
  //     const result = await ContractService.updateExperiences(id, values);
  //     if (!result) {
  //       throw new Error();
  //     }
  //     return result;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const deleteContractExperiences = async (id, expId) => {
  //   try {
  //     setLoading(true);
  //     const result = await ContractService.deleteExperiences(id, expId);
  //     if (!result) {
  //       throw new Error();
  //     }
  //     return result;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <BookingContext.Provider
      value={{
          loading,
          total,
          booking,
          setBooking,
          bookings,
          getBooking,
          getBookings,
          // addBooking,
          updateBooking,
          bookingLogs,
          totalBookingLogs,
          loadingBookingLogs,
          setBookingLogs,
          getBookingLogs,
      }}>
      {children}
    </BookingContext.Provider>
  );
};

BookingProvider.displayName = 'BookingProvider';

export const useBooking = () => useContext(BookingContext);

import React, { useContext, useState } from 'react';
import { ResourceService } from 'core/api/services/resource';

const ResourceContext = React.createContext(null);

export const ResourceProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [resource, setResource] = useState([]);
  const [total, setTotal] = useState([]);


  const getResources = async (options) => {
    try {
      
      setLoading(true);
      const result = await ResourceService.getList(options);

      if (!result) {
        throw new Error();
      }
      setResources(result.resources);
      setTotal(result.total)
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getResource = async (id) => {
    try {
      
      setLoading(true);
      const result = await ResourceService.get(id);
      if (!result) {
        throw new Error();
      }
      setResource(result);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const addResource = async (values) => {
    try {
      setLoading(true);
      const {resource} = await ResourceService.add(values);
      if (!resource) {
        throw new Error();
      }
      setResource(resource);
      return resource;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateResource = async (values) => {
    try {
      setLoading(true);
      const result = await ResourceService.update(values);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <ResourceContext.Provider
      value={{
          loading,
          total,
          resource,
          setResource,
          resources,
          getResource,
          getResources,
          addResource,
          updateResource,
      }}>
      {children}
    </ResourceContext.Provider>
  );
};

ResourceProvider.displayName = 'ResourceProvider';

export const useResource = () => useContext(ResourceContext);

import React, { useContext, useState } from 'react';
import { UserService } from 'core/api/services/user';
import { useAuth } from 'core/contexts/auth';

const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState([]);

  const { userInfo, getUserInfo } = useAuth();

  const getUsers = async (options) => {
    try {
      
      setLoading(true);
      const result = await UserService.get(options);

      // location.reload();
      if (!result) {
        throw new Error();
      }
      setUsers(result.users);
      setTotal(result.total)

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // const update = async (values) => {
  //   try {
  //     setLoading(true);
  //     const result = await ExperiencerService.update(values.experiencer);
  //     console.log('SENT===', values.experiencer, 'RECEIVED===', result);
  //     if (!result) {
  //       throw new Error();
  //     }

  //     getUserInfo();

  //     return result;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <UserContext.Provider
      value={{
        getUsers,
        // update,
        total,
        loading,
        users
      }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.displayName = 'UserProvider';

export const useUser = () => useContext(UserContext);

import React, { useContext, useState } from 'react';
import { ExperiencerService } from 'core/api/services/experiencer';
import { useAuth } from 'core/contexts/auth';

const ExperiencerContext = React.createContext(null);

export const ExperiencerProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [experiencer, setExperiencer] = useState([]);
    const [experiencers, setExperiencers] = useState([]);
    const [total, setTotal] = useState([]);

    const { userInfo, getUserInfo } = useAuth();

    const getExperiencers = async (options) => {
        try {
            setLoading(true);
            const result = await ExperiencerService.getExperiencers(options);

            // location.reload();
            if (!result) {
                throw new Error();
            }
            setExperiencers(result.experiencers);
            setTotal(result.total);

            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getExperiencer = async (id) => {
        try {
            setLoading(true);
            const result = await ExperiencerService.get(id);
            if (!result) {
                throw new Error();
            }
            setExperiencer(result);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const updateExperiencer = async (values) => {
        try {
            setLoading(true);
            const result = await ExperiencerService.update(values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <ExperiencerContext.Provider
            value={{
                getExperiencers,
                // update,
                total,
                loading,
                experiencers,
                experiencer,
                setExperiencer,
                getExperiencer,
                updateExperiencer,
            }}>
            {children}
        </ExperiencerContext.Provider>
    );
};

ExperiencerProvider.displayName = 'ExperiencerProvider';

export const useExperiencer = () => useContext(ExperiencerContext);

import jsonData from "./booking_status.json";
import Status from '../Status.mjs'

class BookingStatus extends Status {
    constructor(params = {}) {
      super({STATUS: jsonData});
    }

    getUiAllowedStatusId = () => [...Object.values(this.STATUS).map(status => status.id ).filter(statusId => {
      if( statusId === 1 ||  // Pending
          statusId === 2 ||  // Booked
          statusId === 3 ||  // Cancelled
          statusId === 8 ||  // No Show
          statusId === 4 )   // Paid
      { return statusId; }
    })];
    getAvailabilityBlockingStatusId = () => [...Object.values(this.STATUS).map(status => status.id ).filter(statusId => {
      if( statusId === 1 || // Pending
          statusId === 2 || // Booked
          statusId === 4 )  // Paid
          { return statusId; }
    })];
 
};

export default BookingStatus = new BookingStatus();
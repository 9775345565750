import { Button, Grid, useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';

import { usePrice } from 'core/contexts/price';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import { useStyles } from './styles';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import BasicTable from 'core/libs/core-ui/components/Table';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import { useExperiencer } from 'core/contexts/experiencer';

const PricesPage = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const { getPriceGroupTabs, prices, loading, total } = usePrice();

    

    const classes = useStyles();
    const history = useHistory();

    const [page, setPage] = useState(1);
    const [nameSearch, setNameSearch] = useState('');
    const MAX_PAGE = 20;

    useEffect(() => {
        getPriceGroupTabs({
            nameSearch,
            pager: {
                page,
                pageSize: MAX_PAGE,
            },
        });
       
    }, []);

    useEffect(() => {
      getPriceGroupTabs({
        nameSearch,
        pager: {
          page,
          pageSize: MAX_PAGE
        }
      });
    }, [page, nameSearch]);

    const columns = [
        { align: 'left', val: 'id', label: 'Id' },
        { val: 'name', label: 'Name' },
        { val: 'experiencerId', label: 'Experiencer Id' },
        { val: 'createdOn', label: 'Created On' },
        { val: 'createdBy', label: 'Created By' },
        { val: 'updatedOn', label: 'Updated On' },
        { val: 'updatedBy', label: 'Updated By' },
        { align: 'center', val: 'status', label: 'Status' },
    ];

    const mobileColumns = [
        { align: 'left', val: 'id', label: 'Id' },
        { val: 'name', label: 'Name' },
        { align: 'center', val: 'status', label: 'Status' },
    ];

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const onChangeNameSearch = (event) => {
        setNameSearch(event.target.value);
    };

    const onClick = (event, id) => {
      history.push(`/price/${id}`)
    }

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Prices'} />
            <div style={{ marginBottom: '25px' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <SearchBox
                        onChange={onChangeNameSearch}
                        value={nameSearch}
                        label={'Name'}
                        style={{ width: '14rem', marginRight: '1rem' }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            history.push(`/price/0`);
                        }}>
                        ADD
                    </Button>
                </Grid>
            </div>

            <div>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <BasicTable columns={!isSmall ? columns : mobileColumns} values={prices} onClick={onClick} />
            <br />
            <Grid container alignItems='flex-end'>
              <Grid item xs={1} sm={7}></Grid>
              <Grid item xs={11} sm={5}>
                {
                  total/MAX_PAGE < 1 ? null:
                (<PaginationControlled count={(Math.ceil(total/MAX_PAGE))} page={page} onChange={handlePageChange} />)
                }
              </Grid>
            </Grid>
          </>
        )
        }
      </div>
        </Layout>
    );
};

PricesPage.displayName = 'PricesPage';

export default PricesPage;

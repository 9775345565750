import React, { useEffect, useRef } from 'react';
import Grid from '@toast-ui/react-grid';
// import 'tui-date-picker/dist/tui-date-picker.css';
// import 'tui-time-picker/dist/tui-time-picker.css';
import 'tui-grid/dist/tui-grid.css';

const DataGrid = ({
    data,
    columns,
    rowHeight,
    bodyHeight,
    heightResizable,
    rowHeaders,
    ...props
}) => {
    // const gridRef = useRef(null);

    return (
        <>
            <Grid
                // ref={gridRef}
                usageStatistics={false}
                data={data}
                columns={columns}
                rowHeight={rowHeight}
                bodyHeight={bodyHeight}
                heightResizable={heightResizable}
                rowHeaders={rowHeaders}
                {...props}
            />
        </>
    );
};

export default DataGrid;
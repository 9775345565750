import { makeStyles } from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
import backgroundUrl from 'assets/images/uTriperLoginImg.jpg'
import uTriperLogo from 'assets/images/uTriperLogo.png'

export const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
    height: '120vh',
    overflow: 'visible',
    "&:before": {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(to bottom, rgba(48, 53, 73, 0.975) 40%, rgba(48, 53, 73, 0) )',
        zIndex: -1,
    },
    "&:after": {

      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'flex-start',
      // background: `url('${backgroundUrl}')`,
      // backgroundRepeat: 'no-repeat',
      
      padding: theme.spacing(4),
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url('${backgroundUrl}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: -2,
    }
    // "&:after": {

    //   display: 'flex',
    //   flexDirection: 'column',
    //   minHeight: '100vh',
    //   alignItems: 'center',
    //   justifyContent: 'flex-start',
    //   background: `url('${backgroundUrl}')`,
    //   backgroundRepeat: 'no-repeat',
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    //   padding: theme.spacing(4),
    //   zIndex: -2,
    // }
  },
  logo: {
    width: '120px',
  },
  itemContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',

    order: 2,
    width: '450px',
    marginTop: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.13)',
    borderRadius: '10px',
    backdropFilter: 'blur(10px)',
    border: '2px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0 0 40px rgba(8, 7, 16, 0.6)',
    padding: '50px 35px',
    marginTop: '50px',

  },
  languageContainer: {
    width: '70vw',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: '2vh 0',
    },
  },
  container:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: '3%',
    height: '100vh',
  },
  containerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageContainer: {
    order: '1',
    width: '400px',
    height: '140px',
    marginTop: '35px',
    marginLeft: '5%',
    backgroundImage: `url('${uTriperLogo}')`,
    backgroundSize: 'cover',
  },

}));

import { MarkupService } from 'core/api/services/markups';
import React, { useContext, useState } from 'react';
const MarkupContext = React.createContext(null);

export const MarkupProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [markups, setMarkups] = useState([]);
    const [markup, setMarkup] = useState([]);
    const [total, setTotal] = useState([]);

    const getMarkup = async (id) => {
        try {
            setLoading(true);
            const result = await MarkupService.get(id);
            if (!result) {
                throw new Error();
            }
            setMarkup(result);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getMarkups = async (options) => {
        try {
            setLoading(true);
            const result = await MarkupService.getList(options);

            if (!result) {
                throw new Error();
            }
            setMarkups(result.markups);
            setTotal(result.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const addMarkup = async (values) => {
        try {
            setLoading(true);
            const markup = await MarkupService.add(values);
            if (!markup) {
                throw new Error();
            }
            setMarkup(markup);
            return markup;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const updateMarkup = async (values) => {
        try {
            setLoading(true);
            const result = await MarkupService.update(values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <MarkupContext.Provider
            value={{
                loading,
                total,
                markups,
                setMarkups,
                getMarkups,
                addMarkup,
                getMarkup,
                updateMarkup,
                markup,
                setMarkup,
            }}>
            {children}
        </MarkupContext.Provider>
    );
};

MarkupProvider.displayName = 'MarkupProvider';

export const useMarkup = () => useContext(MarkupContext);

import { DiscountService } from 'core/api/services/discounts';
import React, { useContext, useState } from 'react';
const DiscountContext = React.createContext(null);

export const DiscountProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [total, setTotal] = useState([]);

    const getDiscount = async (id) => {
    try {
      setLoading(true);
      const result = await DiscountService.get(id);
      if (!result) {
        throw new Error();
      }
      setDiscount(result);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };


  const getDiscounts = async (options) => {
    try {
      setLoading(true);
      const result = await DiscountService.getList(options);

      if (!result) {
        throw new Error();
      }
      setDiscounts(result.discounts);
      setTotal(result.total)
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

    const addDiscount = async (values) => {
    try {
      setLoading(true);
      const discount = await DiscountService.add(values);
      if (!discount) {
        throw new Error();
      }
      setDiscount(discount);
      return discount;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateDiscount = async (values) => {
    try {
      setLoading(true);
      const result = await DiscountService.update(values);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <DiscountContext.Provider
      value={{
        loading,
        total,
        discounts,
        setDiscounts,
        getDiscounts,
        addDiscount,
        getDiscount,
        updateDiscount,
        discount,
        setDiscount
      }}>
      {children}
    </DiscountContext.Provider>
  );
};

DiscountProvider.displayName = 'DiscountProvider';

export const useDiscount = () => useContext(DiscountContext);

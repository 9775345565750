import React, { useContext, useState } from 'react';
import { HostService } from 'core/api/services/host';

const HostContext = React.createContext(null);

export const HostProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [host, setHost] = useState([]);
  // const [contractExperiences, setContractExperiences] = useState([]);
  const [total, setTotal] = useState([]);
  // const [totalExperiences, setTotalExperiences] = useState([]);

  const getHosts = async (options) => {
    try {
      
      setLoading(true);
      const result = await HostService.getList(options);

      if (!result) {
        throw new Error();
      }
      setHosts(result.hosts);
      setTotal(result.total)
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // const getContractExperience = async (id,options) => {
  //   try {
      
  //     setLoading(true);
  //     const result = await ContractService.getExperienceList(id,options);

  //     if (!result) {
  //       throw new Error();
  //     }
  //     setContractExperiences(result.experiences);
  //     setTotalExperiences(result.total);
  //     return true;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getHost = async (id) => {
    try {
      
      setLoading(true);
      const result = await HostService.get(id);
      if (!result) {
        throw new Error();
      }
      setHost(result);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const addHost = async (values) => {
    try {
      setLoading(true);
      const host = await HostService.add(values);
      if (!host) {
        throw new Error();
      }
      setHost(host);
      return host;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateHost = async (values) => {
    try {
      setLoading(true);
      const result = await HostService.update(values);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // const updateContractExperiences = async (id, values) => {
  //   try {
  //     setLoading(true);
  //     const result = await ContractService.updateExperiences(id, values);
  //     if (!result) {
  //       throw new Error();
  //     }
  //     return result;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const deleteContractExperiences = async (id, expId) => {
  //   try {
  //     setLoading(true);
  //     const result = await ContractService.deleteExperiences(id, expId);
  //     if (!result) {
  //       throw new Error();
  //     }
  //     return result;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <HostContext.Provider
      value={{
          loading,
          total,
          // totalExperiences,
          host,
          setHost,
          // contractExperiences,
          hosts,
          // setContract,
          getHost,
          // getContractExperience,
          getHosts,
          addHost,
          updateHost,
          // updateContractExperiences,
          // deleteContractExperiences,
      }}>
      {children}
    </HostContext.Provider>
  );
};

HostProvider.displayName = 'HostProvider';

export const useHost = () => useContext(HostContext);

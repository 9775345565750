import jsonData from "./experience_status.json";
import Status from '../Status.mjs'

class ExperienceStatus extends Status {
    constructor(params = {}) {
      super({STATUS: jsonData});
    }

    getActiveIds = () => [...Object.values(this.STATUS).map(status => status.id ).filter(statusId =>
      ( statusId === this.IDS.draft ||  // Draft 
          statusId === this.IDS.live   // Live 
        )
    )];
};

export default ExperienceStatus = new ExperienceStatus();

import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseBundleToModel, parseBundleToObject } from 'core/api/parser/bundle';

const BundleRoutes = {
    get: (id) => `/api/v1.1.0/admin/bundle/${id}`,
    getList: (options) => `/api/v1.1.0/admin/bundle?name=${options.nameSearch}&${getPaging(options)}`,
    getExperienceList: (id, options) => `/api/v1.1.0/admin/bundle/${id}/experience?${getPaging(options)}`,
    add: '/api/v1.1.0/admin/bundle',
    update: (id) => `/api/v1.1.0/admin/bundle/${id}`,
    addExperiences: (id) => `/api/v1.1.0/admin/bundle/${id}/experience`, 
    deleteExperiences: (id, expId) => `/api/v1.1.0/admin/bundle/${id}/experience/${expId}`,
    updateExperience: (id,expId) => `/api/v1.1.0/admin/bundle/${id}/experience/${expId}`,
};

export const BundleService = {

    get: async (id) => {
        try {
            const { data } = await fetch(BundleRoutes.get(id), {
                method: Method.GET,
                authenticated: true
            });
            return !data || !data.length ? {} : parseBundleToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },
    getList: async (options) => {
        try {
            const { data } = await fetch(BundleRoutes.getList(options), {
                method: Method.GET,
                authenticated: true
            });
            return !data ? { bundles: [], total: 0 } : { total: data.total, bundles: data.bundles.map(parseBundleToModel) };
        } catch (err) {
            throw new Error(err);
        }
    },

    getExperienceList: async (id, options) => {
        try {
            const { data } = await fetch(BundleRoutes.getExperienceList(id, options), {
                method: Method.GET,
                authenticated: true
            });
            return !data ? { experiences: [], total: 0 } : { total: data.total, experiences: data.experiences };
        } catch (err) {
            throw new Error(err);
        }
    },

    add: async (info) => {
        try {
            const { data } = await fetch(BundleRoutes.add, {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.status ? false : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(BundleRoutes.update(info.id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateExperience: async (id, expId, info) => {
        try {
            const { data } = await fetch(BundleRoutes.updateExperience(id,expId), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    addExperiences: async (id, info,) => {
        try {
            const { data } = await fetch(BundleRoutes.addExperiences(id), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    deleteExperiences: async (id, expId) => {
        try {
            const { data } = await fetch(BundleRoutes.deleteExperiences(id, expId), {
                method: Method.DELETE,
                authenticated: true,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function PaginationControlled({ ...props }) {
  const classes = useStyles();
  //   const [page, setPage] = React.useState(1);
  //   const handleChange = (event, value) => {
  //     setPage(value);
  //   };

  return (
    <div className={classes.root}>
      <Pagination
        count={props.count || 10}
        page={props.page}
        onChange={props.onChange}
      />
    </div>
  );
}

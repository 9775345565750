import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseDiscountToModel, parseDiscountToObject } from 'core/api/parser/discounts';

const DiscountsRoutes = {
  get: (id) => `/api/v1.1.0/admin/discount/${id}`,
  getList: (options) => `/api/v1.1.0/admin/discount?${getPaging(options)}`,
  add: '/api/v1.1.0/admin/discount',
  update: (id) => `/api/v1.1.0/admin/discount/${id}`,
};

export const DiscountService = {
    get: async (id) => {
        try {
            const { data } = await fetch(DiscountsRoutes.get(id), {
                method: Method.GET,
                authenticated: true
            });
            return !data || !data.length ? {} : parseDiscountToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },

    getList: async (options) => {
        try {
            const { data } = await fetch(DiscountsRoutes.getList(options), {
                method: Method.GET,
                authenticated: true
            });
            return !data ? {discounts:[],total:0} : {total: data.total, discounts: data.discounts.map(parseDiscountToModel)};
        } catch (err) {
            throw new Error(err);
        }
    },

      add: async (info) => {
        try {
            const { data } = await fetch(DiscountsRoutes.add, {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.status ? false : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(DiscountsRoutes.update(info.id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
};

import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseHostToModel, parseHostToObject } from 'core/api/parser/host';

const HostRoutes = {
    get: (id) => `/api/v1.1.0/admin/host/${id}`,
    getList: (options) => `/api/v1.1.0/admin/host?name=${options.nameSearch}&${getPaging(options)}`,
    // getExperienceList: (id,options) => `/api/v1.1.0/admin/contract/${id}/experience?${getPaging(options)}`,
    add: '/api/v1.1.0/admin/host',
    update: (id) => `/api/v1.1.0/admin/host/${id}`,
    // updateExperiences: (id) => `/api/v1.1.0/admin/contract/${id}`,
    // deleteExperiences: (id, expId) => `/api/v1.1.0/admin/contract/${id}/experience/${expId}`,
    uploadImage: (id) => `/api/v1.1.0/admin/host/${id}/image`,
    // getQRCode: (id) => `/api/v1.1.0/admin/contract/${id}/qr`,
};

export const HostService = {

    get: async (id) => {
        try {
            const { data } = await fetch(HostRoutes.get(id), {
                method: Method.GET,
                authenticated: true
            });
            return !data || !data.length ? {} : parseHostToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },
    getList: async (options) => {
        try {
            const { data } = await fetch(HostRoutes.getList(options), {
                method: Method.GET,
                authenticated: true
            });
            // return !data || !data.length ? [] : data.map(parseContractToModel);
            return !data ? {hosts:[],total:0} : {total: data.total, hosts: data.hosts.map(parseHostToModel)};
        } catch (err) {
            throw new Error(err);
        }
    },

    // getExperienceList: async (id,options) => {
    //     try {
    //         const { data } = await fetch(HostRoutes.getExperienceList(id,options), {
    //             method: Method.GET,
    //             authenticated: true
    //         });
    //         // return !data || !data.length ? [] : data.map(parseContractToModel);
    //         return !data ? {experiences:[],total:0} : {total: data.total, experiences: data.experiences};
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },

    add: async (info) => {
        try {
            const { data } = await fetch(HostRoutes.add, {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.status ? false : data;// parseContractToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(HostRoutes.update(info.id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    // updateExperiences: async (id, info) => {
    //     try {
    //         const { data } = await fetch(HostRoutes.updateExperiences(id), {
    //             method: Method.POST,
    //             authenticated: true,
    //             data: info,
    //         });
    //         return Array.isArray(data) ? data[0] : false;
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },

    // deleteExperiences: async (id, expId) => {
    //     try {
    //         const { data } = await fetch(HostRoutes.deleteExperiences(id, expId), {
    //             method: Method.DELETE,
    //             authenticated: true,
    //         });
    //         return Array.isArray(data) ? data[0] : false;
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },

    uploadHostPicture: async (id, image) => {
        try {
            const formData = new FormData();
            image =
                image.type && image.type !== ''
                    ? image
                    : image.slice(0, image.size, 'image/png');
            formData.append('images', image, image.name ?? `host_${id}.png`);
            await fetch(HostRoutes.uploadImage(id), {
                method: Method.POST,
                authenticated: true,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (err) {
            throw new Error(err);
        }
    },

    // getQRCode: async (id) => {
    //     try {
    //         const { data } = await fetch(HostRoutes.getQRCode(id), {
    //             method: Method.GET,
    //             authenticated: true
    //         });
    //         return Array.isArray(data) ? data[0] : false;
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },
};

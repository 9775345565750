import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseUserToModel } from 'core/api/parser/user';

const UserRoutes = {
  get: (options) => `/api/v1.1.0/admin/user?email=${options.emailSearch}&${getPaging(options)}`,
};

export const UserService = {
  get: async (options) => {
    try {
      const { data } = await fetch(UserRoutes.get(options), {
        method: Method.GET,
        authenticated: true
      });
      return !data ? {users:[],total:0} : {total: data.total, users: data.users.map(parseUserToModel)};
    } catch (err) {
      throw new Error(err);
    }
  },
};

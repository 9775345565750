/* eslint-disable import/no-anonymous-default-export */
import palette from './palette';

export default {
  MuiPickersStaticWrapper: {
    staticWrapperRoot: {
      minWidth: 0,
    },
  },
  MuiAppBar: {
    root: {
      display: 'flex',
      backgroundColor: 'white',
      borderBottom: '1px solid #DADADA',
    },
  },
  MuiToolbar: {
    root: {
      color: palette.primary.main,
      backgroundColor: palette.colors.white,
    },
  },
  MuiDrawer: {
    paperAnchorLeft: {
      // backgroundColor: palette.primary.main,
    },
  },
  MuiListItem: {
    root: {
      paddingTop: 16,
      paddingBottom: 16,
      color: palette.colors.black,
    },
  },
  MuiListItemIcon: {
    root: {
      color: palette.colors.black,
    },
  },
  MuiInputBase: {
    root: {
      height: 42,
      width: '100%',
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
    },
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 400,
    },
  },
  MuiFormHelperText: {
    root: {
      lineHeight: '22px',
      fontSize: '12px',
      color: '#4f4f4f',
      fontWeight: 400,
      fontStyle: 'italic',
    },
    contained: {
      margin: '2px 0 0 2px',
    },
  },
  MuiOutlinedInput: {
    adornedEnd: {
      paddingRight: 0,
    },
    input: {
      padding: '11px 14px',
    },
  },
  MuiStepLabel: {
    iconContainer: {
      paddingRight: 0,
    },
    label: {
      '&.MuiStepLabel-alternativeLabel': {
        minHeight: 40,
      },
    },
  },
  MuiGrid: {
    'spacing-xs-2': {
      '& > $item': {
        padding: '0 8px',
      },
    },
  },
  MuiDialog: {
    paper: {
      borderRadius: 8,
      boxShadow: 'none',
    },
  },
  MuiDialogTitle: {
    root: {
      padding: '20px 24px 0',
    },
  },
  MuiDialogContent: {
    root: {
      padding: '20px 24px 0',
    },
  },
  MuiDialogActions: {
    root: {
      padding: '16px 8px 8px 24px',
    },
  },
  MuiDialogContentText: {
    root: {
      marginBottom: 0,
      color: palette.text.primary,
    },
  },
  MuiIconButton: {
    root: {
      color: palette.primary.main,
      opacity: 0.7,
    },
  },
};

export const parseUserToModel = (user) => ({
    id: user?.id,
    email: user?.email,
    status: user?.status,
    languageId: user?.languageId,
    createdOn: user?.createdOn,
    lastLogin: user?.lastLogin,
    lastPasswordReset: user?.lastPasswordReset
  });
  
//   export const parseTriperToObject = (data) => ({
//     ...data,
//   });
  
//   export const parseBookingToObject = (booking) => {
//     // console.log('Parsing booking...', booking);
//     return {
//       code: booking.code,
//       id: booking.id,
//       experienceId: booking.experienceId,
//       experienceDetailId: booking.experienceDetailId,
//       email: booking.email,
//       triper: `${booking.firstName} ${booking.lastName}`,
//       mobilePhone: booking.mobilePhone,
//       mobilePhoneCountryCode: booking.mobilePhoneCountryCode,
//       experienceName: booking.experienceName,
//       paidPrice: booking.paidPrice,
//       totalPrice: booking.totalPrice,
//       toBePaidPrice: booking.totalPrice - booking.paidPrice,
//       tickets: booking.quantity,
//       status: booking.status,
//       originId: booking.originId,
//       isEditing: false,
//       date: booking.date,
//       formattedDate: {
//         date: booking.date.slice(0, booking.date.indexOf('T')),
//         time: booking.date.slice(
//           booking.date.indexOf('T') + 1,
//           booking.date.lastIndexOf(':')
//         ),
//       },
//       // date: booking.date.slice(0, booking.date.indexOf('T')),
//       // hour: booking.date.slice(booking.date.indexOf('T') + 1, booking.date.lastIndexOf(':')),
//     };
//   };
  
import React, { useState } from 'react';
import DataGrid from '../DataGrid';
import { Box } from '@material-ui/core';

const processData = (categories) => {
    for (let i = 0; i < categories.length; i++) {
        const cat = categories[i];
        cat.resources = cat.resources.length
            ? cat.resources.map((res) => ({
                  resourceId: res.id ?? res.resourceId,
                  resourceName: res.name ?? res.resourceName,
                  categoryId: cat.resourceCategoryId ?? cat.resourceCategoryId,
              }))
            : [];
        cat.categoryId = cat.resourceCategoryId;
        cat.categoryName = cat.name;
    }
    return categories;
};

class CustomCheckboxEditor {
    constructor(props) {
        const ul = document.createElement('ul');
        ul.className = 'tui-grid-editor-checkbox-list-layer';
        ul.style.minWidth = '122px';
        ul.style.opacity = '1';
        ul.style.position = 'absolute';
        ul.style.backgroundColor = '#fff';
        ul.style.border = '1px solid #aaa';
        ul.style.zIndex = '100';
        ul.style.maxHeight = '180px';

        const resources = props.value;
        const handleSelectedResource =
            props.columnInfo.editor.options.handleSelectedResource;
        const { grid, rowKey, columnInfo } = props;

        resources.forEach((resource) => {
            const li = document.createElement('li');
            li.id = `checkbox-${resource.resourceId}`;
            li.className = 'tui-grid-editor-checkbox';

            const checkbox = document.createElement('input');
            checkbox.type = 'checkbox';
            checkbox.name = 'checkbox';
            checkbox.value = resource.resourceId;
            checkbox.checked = resource.selected;
            checkbox.onchange = () => {
                handleSelectedResource(
                    resource.resourceId,
                    resource.categoryId,
                    checkbox.checked
                );
                const newValue = resources.map((res) => {
                    if (res.resourceId === resource.resourceId) {
                        return { ...res, selected: checkbox.checked };
                    }
                    return res;
                });
                grid.setValue(rowKey, columnInfo.name, newValue);
            };

            const label = document.createElement('label');
            label.className = checkbox.checked
                ? 'tui-grid-editor-label-icon-checkbox-checked'
                : 'tui-grid-editor-label-icon-checkbox';
            label.appendChild(checkbox);

            const span = document.createElement('span');
            span.textContent = resource.resourceName;
            label.appendChild(span);

            li.appendChild(label);
            ul.appendChild(li);
        });

        this.el = ul;
        this.grid = grid;
        this.rowKey = rowKey;
        this.columnInfo = columnInfo;
    }

    getElement() {
        return this.el;
    }

    getValue() {
        return this.grid.getValue(this.rowKey, this.columnInfo.name);
    }

    beforeDestroy() {
        this.el.removeChild(this.el.children[0]);
    }
}

const ResourceGrid = ({ resources, setResourceSelected }) => {
    const [gridData, setGridData] = useState(processData(resources));

    const handleSelectedResource = (resourceId, categoryId, isSelected) => {
        setResourceSelected((prev) => {
            let newState = [...prev];
            let categoryIndex = newState.findIndex(
                (item) => Object.keys(item)[0] === categoryId.toString()
            );

            if (isSelected) {
                if (categoryIndex === -1) {
                    newState.push({ [categoryId]: [resourceId] });
                } else {
                    newState[categoryIndex][categoryId].push(resourceId);
                }
            } else {
                if (categoryIndex !== -1) {
                    newState[categoryIndex][categoryId] = newState[
                        categoryIndex
                    ][categoryId].filter((id) => id !== resourceId);
                    if (newState[categoryIndex][categoryId].length === 0) {
                        newState.splice(categoryIndex, 1);
                    }
                }
            }
            return newState;
        });
    };
    function selectedResourcesFormatter(props) {
        const selectedResources = props.value
            .filter((resource) => resource.selected)
            .map((resource) => resource.resourceName)
            .join(', ');

        return selectedResources;
    }

    const columns = [
        {
            id: 'categoryId',
            name: 'categoryName',
            header: 'Categoría',
            width: 140,
        },
        {
            name: 'resources',
            header: 'Recursos',
            editor: {
                type: CustomCheckboxEditor,
                options: {
                    handleSelectedResource: handleSelectedResource,
                },
            },
            formatter: selectedResourcesFormatter,
            width: 200,
        },
    ];
    React.useEffect(() => {
        setGridData(processData(resources));
    }, [resources]);

    return (
        <Box>
            <DataGrid
                data={gridData}
                columns={columns}
                heightResizable={true}
                editable={true}
            />
        </Box>
    );
};

export default ResourceGrid;

function MoneyFormattedUtils(amount, language, options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
}) {

    if (!language) {
        language = 'es-ES';
    }

    return Number.parseFloat(amount).toLocaleString(language, options);
}

function formatCurrency(value, currencyCode, locale = 'es-CO') {

    if(!value || !currencyCode){
        return ''
    }
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formatter.format(value);
}

export { MoneyFormattedUtils, formatCurrency };

export const parseExperienceToModel = (experience) => ({
  ...experience,
  payNowType: experience.payNowType || 0,
});
  
export const parseExperienceToObject = (data) => ({
  ...data,
  payNowType: data.payNowType || 0,
});

export const parseExperienceScheduleToModel = (experienceSchedule) => ({
  extendedProps: { ...experienceSchedule},
  id: experienceSchedule.id,
  start: new Date(experienceSchedule.startUTC).toISOString(),
  end: new Date(experienceSchedule.endUTC).toISOString(),
});
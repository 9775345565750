import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getSession } from 'core/api/session';

const RedirectRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = getSession() !== null;

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default RedirectRoute;

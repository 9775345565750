import { isEmpty } from 'lodash';
import { fetch, Method } from 'core/api/fetch';

import { parseScheduleMonthToModel } from 'core/api/parser/schedule';

const scheduleRoutes = {
  getByDateMonth: (expId, expDetailId, date, mod, groupSize, code) => `api/v1.1.0/experience/${expId}/availabilty?day=${date.toISOString()}${mod ? `&mod=${mod}` : ''}&group_size=${groupSize ?? 1}${code ? `&code=${code}` : ''}`,
  getAvailability: ({expId, fromDateTime, toDateTime, groupSize, code}) => `api/v1.1.1/experience/${expId}/availabilty?fromDateTime=${fromDateTime.toISOString()}&toDateTime=${toDateTime.toISOString()}&group_size=${groupSize ?? 1}${code ? `&code=${code}` : ''}`,
};

export const ScheduleService = {
  getByDateMonth: async (expId, expDetailId, date, mod, groupSize, code) => {
    try {
      const { data } = await fetch(scheduleRoutes.getByDateMonth(expId, expDetailId, date, mod, groupSize, code), {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return isEmpty(data) ? [] : data.map(parseScheduleMonthToModel);
    } catch (err) {
      throw new Error(err);
    }
  },
  getAvailability: async ({expId, fromDateTime, toDateTime, groupSize, code}) => {
    try {
      const { data } = await fetch(scheduleRoutes.getAvailability({expId, fromDateTime, toDateTime, groupSize, code}), {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return isEmpty(data) ? [] : data.map(parseScheduleMonthToModel);
    } catch (err) {
      throw new Error(err);
    }
  },
};

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ROUTES } from './routes';
import RedirectRoute from './redirectRoute';

import LandingPage from 'modules/Landing';
import HomePage from 'modules/Home';
import UsersPage from 'modules/Users';
import ExperiencersPage from 'modules/Experiencers';
import ExperiencerPage from 'modules/Experiencers/Experiencer';
import ExperiencesPage from 'modules/Experiences';
import ExperiencePage from 'modules/Experiences/Experience';
import ContractsPage from 'modules/Contracts';
import ContractPage from 'modules/Contracts/Contract';
import HostsPage from 'modules/Hosts';
import HostPage from 'modules/Hosts/Host';
import BookingsPage from 'modules/Bookings';
import BookingPage from 'modules/Bookings/Booking';
import PaymentsPage from 'modules/Payments';
// import PaymentPage from 'modules/Payments/Payment';
import BundlesPage from 'modules/Bundles';
import BundlePage from 'modules/Bundles/Bundle';
import ResourcesPage from 'modules/Resources';
import ResourcePage from 'modules/Resources/Resource';
import DiscountsPage from 'modules/Discounts';
import DiscountPage from 'modules/Discounts/Discount';
import MarkupsPage from 'modules/Markups';
import MarkupPage from 'modules/Markups/Markup';
import PricesPage from 'modules/Prices';
import PricePage from 'modules/Prices/Price';

// import ViajeroBooking from 'modules/Calendar';
// import ViajeroCheckout from 'modules/Checkout';
// import CheckoutValidation from "modules/Checkout/Validate";
// import ViajeroSavedExperiences from 'modules/SavedExperiences';
// import HostelTemplate from "modules/Landing/Hostel";
// import PurchaseTemplate from "modules/Purchase";
// import ErrorTemplate from 'modules/Errors';
// import PendingTemplate from 'modules/Pending';

const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route path={ROUTES.LANDING} exact>
                <LandingPage />
            </Route>

            <RedirectRoute path={ROUTES.HOME} component={HomePage} />
            <RedirectRoute path={ROUTES.USERS} component={UsersPage} exact />
            <RedirectRoute
                path={ROUTES.EXPERIENCERS}
                component={ExperiencersPage}
                exact
            />
            <RedirectRoute
                path={ROUTES.EXPERIENCER}
                component={ExperiencerPage}
            />
            <RedirectRoute
                path={ROUTES.EXPERIENCES}
                component={ExperiencesPage}
                exact
            />
            <RedirectRoute
                path={ROUTES.EXPERIENCE}
                component={ExperiencePage}
            />
            <RedirectRoute
                path={ROUTES.CONTRACTS}
                component={ContractsPage}
                exact
            />
            <RedirectRoute path={ROUTES.CONTRACT} component={ContractPage} />
            <RedirectRoute path={ROUTES.HOSTS} component={HostsPage} exact />
            <RedirectRoute path={ROUTES.HOST} component={HostPage} />
            <RedirectRoute
                path={ROUTES.BOOKINGS}
                component={BookingsPage}
                exact
            />
            <RedirectRoute path={ROUTES.BOOKING} component={BookingPage} />
            <RedirectRoute path={ROUTES.PRICES} component={PricesPage} exact />
            <RedirectRoute path={ROUTES.PRICE} component={PricePage} />
            <RedirectRoute
                path={ROUTES.PAYMENTS}
                component={PaymentsPage}
                exact
            />
            {/* <RedirectRoute path={ROUTES.PAYMENT} component={PaymentPage} /> */}
            <RedirectRoute
                path={ROUTES.BUNDLES}
                component={BundlesPage}
                exact
            />
            <RedirectRoute path={ROUTES.BUNDLE} component={BundlePage} />
            <RedirectRoute
                path={ROUTES.RESOURCES}
                component={ResourcesPage}
                exact
            />
            <RedirectRoute path={ROUTES.RESOURCE} component={ResourcePage} />
            <RedirectRoute
                path={ROUTES.DISCOUNTS}
                component={DiscountsPage}
                exact
            />
            <RedirectRoute path={ROUTES.DISCOUNT} component={DiscountPage} />
            <RedirectRoute
                path={ROUTES.MARKUPS}
                component={MarkupsPage}
                exact
            />
            <RedirectRoute path={ROUTES.MARKUP} component={MarkupPage} />
            {/* <Route path={ROUTES.LANDINGHOSTEL} exact>
        <LandingHostel />
      </Route> */}

            {/* <Route path={ROUTES.BOOKING_CODE}>
          <ViajeroBooking />
        </Route>

        <Route path={ROUTES.BOOKING}>
          <ViajeroBooking />
        </Route>

        <Route path={ROUTES.CHECKOUT_VALIDATION} exact>
          <CheckoutValidation />
        </Route>

        <Route path={ROUTES.CHECKOUT}>
          <ViajeroCheckout />
        </Route>

        <Route path={ROUTES.SAVEDEXPERIENCES}>
          <ViajeroSavedExperiences />
        </Route>

        <Route path={ROUTES.QR}>
          <HostelTemplate />
        </Route>

        <Route path={ROUTES.PURCHASE}>
          <PurchaseTemplate goBackUrl="" />
        </Route>

        <Route path={ROUTES.ERROR}>
          <ErrorTemplate goBackUrl="" />
        </Route>

        <Route path={ROUTES.PENDING}>
          <PendingTemplate goBackUrl="" />
        </Route> */}
        </Switch>
    </BrowserRouter>
);

Router.displayName = 'Router';

export default Router;

import i18n from 'i18next';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

const get = () => {
  switch (i18n.language) {
    default:
    case 'es':
      return esLocale;
    case 'en':
      return enLocale;
  }
};

export default get;

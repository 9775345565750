import React, { useState } from 'react';
import {
    TextField,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import { CalendarToday, Close } from '@material-ui/icons';
import DatePicker from '../DatePicker';

function DatePickerWithInput({ label, value, onChange, ...props }) {
    const [showCalendar, setShowCalendar] = useState(false);

    const handleCalendarToggle = () => {
        setShowCalendar(!showCalendar);
    };

    const handleDateChange = (date) => {
        onChange(date);
        setShowCalendar(false);
    };

    return (
        <div style={{ position: 'relative', ...props.style }}>
            <TextField
                className={props.classes}
                style={props?.inputProps?.style}
                id="input-with-icon-textfield"
                label={label}
                value={value}
                fullWidth
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {value && (
                                <>
                                <IconButton onClick={handleCalendarToggle}>
                                    <CalendarToday />
                                 </IconButton>
                                <IconButton onClick={() => handleDateChange({})}>
                                    <Close />
                                </IconButton>
                                </>
                            )}
                        </InputAdornment>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            {!value && (
                            <IconButton onClick={handleCalendarToggle}>
                                <CalendarToday />
                            </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            {showCalendar && (
                <div style={{
                        position: 'absolute',
                        zIndex: 1000,
                    }}>
                    <DatePicker
                        label={label}
                        value={value}
                        onChange={handleDateChange}
                    />
                </div>
            )}
        </div>
    );
}

export default DatePickerWithInput;

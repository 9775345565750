import React, { useContext, useState } from 'react';
import { ContractService } from 'core/api/services/contract';

const ContractContext = React.createContext(null);

export const ContractProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState([]);
    const [contractExperiences, setContractExperiences] = useState([]);
    const [contractBundles, setContractBundles] = useState([]);
    const [total, setTotal] = useState([]);
    const [totalExperiences, setTotalExperiences] = useState([]);
    const [totalBundles, setTotalBundles] = useState([]);

    const getContracts = async (options) => {
        try {
            setLoading(true);
            const result = await ContractService.getList(options);

            if (!result) {
                throw new Error();
            }
            setContracts(result.contracts);
            setTotal(result.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getContractExperience = async (id, options) => {
        try {
            setLoading(true);
            const result = await ContractService.getExperienceList(id, options);

            if (!result) {
                throw new Error();
            }
            setContractExperiences(result.experiences);
            setTotalExperiences(result.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getContractBundle = async (id, options) => {
        try {
            setLoading(true);
            const result = await ContractService.getBundleList(id, options);
            if (!result) {
                throw new Error();
            }
            setContractBundles(result.bundle);
            setTotalBundles(result.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getContract = async (id) => {
        try {
            setLoading(true);
            const result = await ContractService.get(id);
            if (!result) {
                throw new Error();
            }
            setContract(result);
            //   setContractExperiences(result.experiences?.experiences);
            setTotalExperiences(result.experiences?.total);
            setTotalBundles(result.bundle?.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const addContract = async (values) => {
        try {
            setLoading(true);
            const result = await ContractService.add(values);
            if (!result) {
                throw new Error();
            }
            setContract(result);
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const updateContract = async (values) => {
        try {
            setLoading(true);
            const result = await ContractService.update(values);
            if (!result) {
                throw new Error();
            }
            //   getUserInfo();
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const addContractExperiences = async (id, values) => {
        try {
            setLoading(true);
            const result = await ContractService.addExperiences(id, values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const updateContractExperience = async (id, expId, values) => {
        try {
            const result = await ContractService.updateExperience(
                id,
                expId,
                values
            );
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const deleteContractExperiences = async (id, expId) => {
        try {
            setLoading(true);
            const result = await ContractService.deleteExperiences(id, expId);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const updateContractBundles = async (id, values) => {
        try {
            setLoading(true);
            const result = await ContractService.updateBundles(id, values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const deleteContractBundles = async (id, expId) => {
        try {
            setLoading(true);
            const result = await ContractService.deleteBundles(id, expId);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <ContractContext.Provider
            value={{
                loading,
                total,
                totalExperiences,
                totalBundles,
                contract,
                contractExperiences,
                contractBundles,
                contracts,
                setContract,
                getContract,
                getContractExperience,
                getContractBundle,
                getContracts,
                addContract,
                updateContract,
                addContractExperiences,
                updateContractExperience,
                deleteContractExperiences,
                updateContractBundles,
                deleteContractBundles,
            }}>
            {children}
        </ContractContext.Provider>
    );
};

ContractProvider.displayName = 'ContractProvider';

export const useContract = () => useContext(ContractContext);

import jsonData from "./pay_now.json";
import Types from '../Types.mjs'

class PayNow extends Types {
    constructor(params = {}) {
      super({TYPES: jsonData});
    }
    isTypePercentage = (payNowId) => {
      return [...Object.values(this.TYPES).map(types => types.id).filter(typesId => {
        return typesId === this.IDS.percentage || typesId === this.IDS.flexiblePercentage;
      })].includes(payNowId);
  };
};

export default PayNow = new PayNow();

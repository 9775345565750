import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid, Button, } from '@material-ui/core';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import { useContract } from 'core/contexts/contract';


import { useStyles } from './styles';

const ContractsPage = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { getContracts, contracts, loading, total } = useContract();

  const classes = useStyles();
  const history = useHistory();

  const [nameSearch, setNameSearch] = useState('');

  const [page, setPage] = useState(1);
  const MAX_PAGE = 20;
  
  useEffect(() => {
    getContracts({
      nameSearch,
      pager: {
        page,
        pageSize: MAX_PAGE
      }
    });
  }, []);

  useEffect(() => {
    getContracts({
      nameSearch,
      pager: {
        page,
        pageSize: MAX_PAGE
      }
    });
  }, [page, nameSearch]);

  const columns = [
    { align: 'left', val: 'id', label: "Id" },
    { val: 'name', label: "Name" },
    { val: 'contactEmail', label: "Admin Email" },
    { val: 'notificationEmail', label: "Notification Email" },
    { val: 'createdOn', label: "Created On", },
    { val: 'location', label: "Location", },
    { align: 'center', val: 'hasQR', label: "QR" },
    { align: 'center', val: 'status', label: "Status" },
  ];
  
  const mobileColumns = [
    { align: 'left', val: 'id', label: 'Id', },
    { val: 'name', label: 'Name', },
    { val: 'contactEmail', label: "Admin Email" },
    { align: 'center', val: 'status', label: 'Status', },
  ];

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onChangeNameSearch = (event) => {
    setNameSearch(event.target.value);
  };

  const onClick = (event, id) => {
    history.push(`/contract/${id}`)
  }

  return (
    <Layout contentClassName={classes.content}>
      <Title value={'Contracts'} />
      <div style={{marginBottom:"25px"}}>
        <Grid container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
          
            <SearchBox
              onChange={onChangeNameSearch}
              value={nameSearch}
              label={'Name'}
              style={{ width: '14rem', marginRight: '1rem' }}
              />
          
          
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                history.push(`/contract/0`)
              }}
              >ADD</Button>
          
        </Grid>
      </div>
      <div>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <BasicTable columns={!isSmall ? columns : mobileColumns} values={contracts} onClick={onClick} />
            <br />
            <Grid container alignItems='flex-end'>
              <Grid item xs={1} sm={7}></Grid>
              <Grid item xs={11} sm={5}>
                {
                  total/MAX_PAGE < 1 ? null:
                (<PaginationControlled count={(Math.ceil(total/MAX_PAGE))} page={page} onChange={handlePageChange} />)
                }
              </Grid>
            </Grid>
          </>
        )
        }
      </div>
    </Layout>
  );
};

ContractsPage.displayName = 'ContractsPage';

export default ContractsPage;

import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseExperiencerToModel, parseExperiencerToObject } from 'core/api/parser/experiencer';

const ExperiencerRoutes = {
  getList: (options) => `/api/v1.1.0/admin/experiencer?multiSearch=${options.multiSearch}&${getPaging(options)}`,
  get: (id) => `/api/v1.1.0/admin/experiencer/${id}`,
  update: (id) => `/api/v1.1.0/admin/experiencer/${id}`,
//   updateAvatar: (experiencer) => `/api/v1.1.0/experiencer/${experiencer}/image`,
};

export const ExperiencerService = {

  getExperiencers: async (options) => {
    try {
      const { data } = await fetch(ExperiencerRoutes.getList(options), {
        method: Method.GET,
        authenticated: true
      });
    //   return !data || !data.length ? [] : data.map(parseExperiencerToModel);
      return !data ? {experiencers:[],total:0} : {total: data.total, experiencers: data.experiencers.map(parseExperiencerToModel)};
    } catch (err) {
      throw new Error(err);
    }
  },

  get: async (options) => {
    try {
      const { data } = await fetch(ExperiencerRoutes.get(options), {
        method: Method.GET,
        authenticated: true
      });
    //   return !data || !data.length ? [] : data.map(parseExperiencerToModel);
      return !data || !data.length ? {} : parseExperiencerToObject(data[0]);
    } catch (err) {
      throw new Error(err);
    }
  },

  update: async (info) => {
    try {
      const { data } = await fetch(ExperiencerRoutes.update(info.id), {
        method: Method.PATCH,
        authenticated: true,
        data: info,
      });
      return Array.isArray(data) ? data[0] : false;
    } catch (err) {
      throw new Error(err);
    }
  },

//   updateAvatar: async (id, image) => {
//     try {
//       const formData = new FormData();
//       image =
//         image.type && image.type !== ''
//           ? image
//           : image.slice(0, image.size, 'image/png');
//       formData.append('images', image, image.name ?? 'avatar.png');
//       await fetch(ExperiencerRoutes.updateAvatar(id), {
//         method: Method.POST,
//         authenticated: true,
//         data: formData,
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//     } catch (err) {
//       throw new Error(err);
//     }
//   },

};

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Grid, Button, useMediaQuery } from '@material-ui/core';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import BasicTable from 'core/libs/core-ui/components/Table';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import Dialog from 'core/libs/core-ui/components/Dialog';
import { useDebounce } from 'core/libs/core-ui/utils/useDebounce';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import PaymentPlatform from 'core/libs/types/PaymentPlatform/PaymentPlatform';

import { usePayment } from 'core/contexts/payment';

import { useStyles } from './styles';
import theme from 'core/theme';


const PaymentsPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const { updatePayment, getPayments, payments, loading, total } = usePayment();

    const [payment, setPayment] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    

    const [emailSearch, setEmailSearch] = useState('');
    const [bookingCodeSearch, setBookingCodeSearch] = useState('');
    const emailDebounce = useDebounce(emailSearch, 300);
    const bookingCodeDebounce = useDebounce(bookingCodeSearch, 300);
    const [orderBy, setOrderBy] = useState('ci_created_on');
    const [direction, setDirection] = useState('desc');
    const [page, setPage] = useState(1);
    const MAX_PAGE = 20;


    useEffect(() => {
        getPayments({
            emailSearch: emailDebounce,
            bookingCodeSearch: bookingCodeDebounce,
            pager: {
                column: orderBy,
                direction,
                page,
                pageSize: MAX_PAGE,
            },
        });
    }, [page, orderBy, direction, emailDebounce, bookingCodeDebounce]);

    const prettifyPayments = (payments = []) => {
        return payments.map((payment) => {
            return {
                triper: `${payment.firstName} ${payment.lastName}`,
                priceString: `${payment.currency} ${MoneyFormattedUtils(
                    payment.totalPrice
                )}`,
                paidPriceString: `${payment.currency} ${MoneyFormattedUtils(
                    payment.totalPaidPrice
                )}`,
                createdOnString: DateFormattedUtils(payment.createdOn),
                updatedOnString: DateFormattedUtils(payment.updatedOn),
                actionButton: payment.statusId === 0 ? renderApprove({...payment}) : '',
                paymentPlatformStr: PaymentPlatform.getLabel(payment.paymentPlatformId),
                ...payment,
            };
        });
    };

    const columns = [
        { align: 'left', val: 'id', label: 'Id', column: 'ci_id' },
        { align: 'left', val: 'code', label: 'Code', column: 'ci_code' },
        {
            val: 'triper',
            label: 'Triper',
            column: 'ci_first_name',
            style: { minWidth: '200px' },
        },
        { val: 'email', label: 'Email', column: 'ci_email' },
        {
            val: 'phoneNumber',
            label: 'Mobile Phone',
            column: 'ci_mobile_phone',
        },
        {
            val: 'createdOnString',
            label: 'Created On',
            column: 'ci_created_on',
            style: { minWidth: '250px' },
        },
        {
            val: 'updatedOnString',
            label: 'Updated On',
            column: 'ci_updated_on',
            style: { minWidth: '250px' },
        },
        {
            val: 'paymentPlatformStr',
            label: 'Payment Platform',
            column: 'ci_payment_platform_id',
        },
        {
            align: 'center',
            val: 'paymentId',
            label: 'Payment Id',
            column: 'ci_payment_id',
        },
        // { align: 'center', val: 'currency', label: "Currency" },
        {
            align: 'center',
            val: 'paidPriceString',
            label: 'Paid Amount',
            column: 'ci_total_paid_price',
            style: { minWidth: '150px' },
        },
        {
            align: 'center',
            val: 'priceString',
            label: 'Price',
            column: 'ci_total_price',
            style: { minWidth: '150px' },
        },
        {
            align: 'center',
            val: 'statusId',
            label: 'Status ID',
            column: 'ci_status_id',
        },
        {
            align: 'center',
            val: 'status',
            label: 'Status',
            column: 'ci_status',
        },
        {
          align: 'center',
          val: 'actionButton',
          label: ' ',  
        },
    ];

    const mobileColumns = [
        { align: 'left', val: 'id', label: 'Id', column: 'ci_id' },
        { val: 'code', label: 'Code', column: 'ci_code' },
        { val: 'email', label: 'Email', column: 'ci_email' },
        {
            val: 'createdOnString',
            label: 'Created On',
            column: 'ci_created_on',
            style: { minWidth: '250px' },
        },
        {
            val: 'updatedOnString',
            label: 'Updated On',
            column: 'ci_updated_on',
            style: { minWidth: '250px' },
        },
        {
            align: 'center',
            val: 'paymentPlatformId',
            label: 'Platform Id',
            column: 'ci_payment_platform_id',
        },
        {
            align: 'center',
            val: 'paymentId',
            label: 'Payment Id',
            column: 'ci_payment_id',
        },
        {
            align: 'right',
            val: 'paidPriceString',
            label: 'Paid Amount',
            column: 'ci_total_paid_price',
            style: { minWidth: '150px' },
        },
        {
            align: 'right',
            val: 'priceString',
            label: 'Price',
            column: 'ci_total_price',
            style: { minWidth: '150px' },
        },
        {
            align: 'center',
            val: 'status',
            label: 'Status',
            column: 'ci_status',
        },
        ,
        {
          align: 'center',
          val: 'actionButton',
          label: ' ',  
        },
    ];

    const handleSort = (column) => {
      if (orderBy === column) {
        setDirection(direction === 'asc' ? 'desc' : 'asc');
      } else {
        setOrderBy(column);
        setDirection('asc');
      }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const onChangeEmailSearch = (event) => {
        setEmailSearch(event.target.value);
    };

    const onChangeBookingCodeSearch = (event) => {
        setBookingCodeSearch(event.target.value);
    };

    const onClick = (event, id) => {
        // history.push(`/payment/${id}`)
    };

    const onSubmitConfirmPayment = async (e) => {
      // TODO FIXME We need to use status lib
      if (!paymentId) {
          return;
      }
      await updatePayment({
          id: payment.id,
          paymentId,
          status: 'approved',
          statusId: 3,
      });
      setPayment(null);
      setOpenForm(false);
      getPayments({
        emailSearch: emailDebounce,
        bookingCodeSearch: bookingCodeDebounce,
        pager: {
            column: orderBy,
            direction,
            page,
            pageSize: MAX_PAGE,
        },
    });
  };

    const renderApprove = (payment) => {
      return (
        <Button
          className={classes.approveButton}
          onClick={() => {
            setPayment(payment);
            setOpenForm(true);
          }}
        >
        Approve
        </Button>
      )
    }

    const renderPaymentDialog = () => {
      return (
          <div>
              <Grid container>
                  <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                      {' '}
                      Monton Total:{' '}
                  </Grid>
                  <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}>
                      {' '}
                      COP$ {MoneyFormattedUtils(payment?.totalPrice)}{' '}
                  </Grid>
              </Grid>
              <Grid
                  container
                  style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                      {' '}
                      Monto cobrado (reserva de cupo):{' '}
                  </Grid>
                  <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}>
                      {' '}
                      COP$ {MoneyFormattedUtils(payment?.totalPaidPrice)}{' '}
                  </Grid>
              </Grid>
              <div>
                  <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Form
                              onSubmit={() => {}}
                              // initialValues={{ ...values }}
                              render={({ handleSubmit, values }) => (
                                  <form
                                      onSubmit={handleSubmit}
                                      // noValidate
                                      style={
                                          isSmall
                                              ? { width: '80vw' }
                                              : { width: 'auto' }
                                      }>
                                      <Field
                                          required
                                          // validate={required}
                                          className="add-experience__capacity"
                                          variant="outlined"
                                          label={
                                              'Código único (Datáfono, MP, Paypal)'
                                          }
                                          name="paymentId"
                                          value={''}
                                          onTextFieldChange={(e) => {
                                              const { name, value } =
                                                  e.target;
                                              setPaymentId(value);
                                          }}
                                          component={TextInput}
                                      />
                                  </form>
                              )}
                          />
                      </Grid>
                  </Grid>
              </div>
          </div>
      );
  };

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Payments'} />
            <div style={{ marginBottom: '25px' }}>
                <SearchBox
                    onChange={onChangeEmailSearch}
                    value={emailSearch}
                    label={'Email'}
                    style={{ width: '14rem', marginRight: '1rem' }}
                />
                <SearchBox
                    onChange={onChangeBookingCodeSearch}
                    value={bookingCodeSearch}
                    label={'Code'}
                    style={{ width: '5rem' }}
                />
            </div>
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        {/* <Button
              onClick={(e) => {
                history.push(`/payment/0`)
              }}
            >ADD</Button> */}
                        <BasicTable
                            size='small'
                            columns={!isSmall ? columns : mobileColumns}
                            handleSort={handleSort}
                            orderBy={orderBy}
                            direction={direction}
                            values={prettifyPayments(payments)}
                            onClick={onClick}
                        />
                        <br />
                        <Grid container alignItems="flex-end">
                            <Grid item xs={1} sm={7}></Grid>
                            <Grid item xs={11} sm={5}>
                                {total / MAX_PAGE < 1 ? null : (
                                    <PaginationControlled
                                        count={Math.ceil(total / MAX_PAGE)}
                                        page={page}
                                        onChange={handlePageChange}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </div>
            <Dialog
                    open={openForm}
                    dialogTitle={'Booking Payment'}
                    dialogContent={renderPaymentDialog()}
                    handleClose={() => {
                        // DELETE BOOKING!!!
                        // setExpToDelete(null);
                        // onDismissConfirmPayment();
                        setPayment(null);
                        setOpenForm(false);
                    }}
                    handleSubmit={onSubmitConfirmPayment}
                    labelBack="Cancel"
                    labelNext="Confirm"
                />
        </Layout>
    );
};

PaymentsPage.displayName = 'PaymentsPage';

export default PaymentsPage;

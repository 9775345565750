import { fetch, Method, getPaging } from 'core/api/fetch';
import { parsePriceToObject } from '../parser/price';

const PriceRoutes = {
    getList: (options) =>
        `/api/v1.1.0/admin/priceGroupTab?name=${options.nameSearch}${
            options.experiencerId
                ? `&experiencerId=${options.experiencerId}`
                : '&'
        }${getPaging(options)}`,
    getId: (pgtId) => `/api/v1.1.0/admin/priceGroupTab/${pgtId}`,
    getExpPg: (id) =>
        `/api/v1.1.0/admin/priceGroupTab/${id}/experiencePriceGroup`,
    addExpPg: () => `/api/v1.1.0/admin/experiencePriceGroup`,
    deleteExpPg: (id) => `/api/v1.1.0/admin/experiencePriceGroup/${id}`,
    add: () => `/api/v1.1.0/admin/priceGroupTab`,
    update: (pgtId) => `/api/v1.1.0/admin/priceGroupTab/${pgtId}`,
    delete: (pgtId) => `/api/v1.1.0/admin/priceGroupTab/${pgtId}`,
    addPrice: (id) => `/api/v1.1.0/admin/priceGroup/${id}/price`,
    updatePrice: (id, priceId) => `/api/v1.1.0/admin/priceGroup/${id}/price/${priceId}`,
    updatePriceCurrency: (id) => `/api/v1.1.0/admin/priceGroup/${id}/price`,
    getExperiencesPriceGroupList: (id) => `/api/v1.1.0/admin/experienceId/${id}/priceGroup`,
    deletePrice: (pgId, id) => `/api/v1.1.0/admin/priceGroup/${pgId}/price/${id}`,
    deletePriceGroup: (id) => `/api/v1.1.0/admin/priceGroup/${id}`,
};

export const PriceService = {
    getList: async (options) => {
        try {
            const { data } = await fetch(PriceRoutes.getList(options), {
                method: Method.GET,
                authenticated: true,
            });

            return !data
                ? { prices: [], total: 0 }
                : {
                      total: data.total,
                      prices: data.priceGroupTabs.map(parsePriceToObject),
                  };
        } catch (err) {
            throw new Error(err);
        }
    },
    getById: async (id) => {
        try {
            const { data } = await fetch(PriceRoutes.getId(id), {
                method: Method.GET,
                authenticated: true,
            });

            return !data || !data.length ? {} : parsePriceToObject(data);
        } catch (err) {
            throw new Error(err);
        }
    },
    getListExpPg: async (id) => {
        try {
            const { data } = await fetch(PriceRoutes.getExpPg(id), {
                method: Method.GET,
                authenticated: true,
            });

            return !data ? false : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    addExpPg: async (info) => {
        try {
            const { data } = await fetch(PriceRoutes.addExpPg(), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });

            return !data ? false : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    deleteExpPg: async (id) => {
      try {
          const { data } = await fetch(PriceRoutes.deleteExpPg(id), {
              method: Method.DELETE,
              authenticated: true,
          });
  
          return !data ? {} : data;
      } catch (err) {
          throw new Error(err);
      }
    },
    add: async (info) => {
        try {
            const { data } = await fetch(PriceRoutes.add(), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.status ? false : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    update: async (pgtId, info) => {
        try {
            const { data } = await fetch(PriceRoutes.update(pgtId), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
    delete: async (id, pgtId) => {
        try {
            const { data } = await fetch(PriceRoutes.delete(id, pgtId), {
                method: Method.DELETE,
                authenticated: true,
            });

            return !data ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    addPrice: async (id, info) => {
        try {
            const { data } = await fetch(PriceRoutes.addPrice(id), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.status ? false : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    updatePrice: async (id, priceId, info) => {
        try {
            const { data } = await fetch(PriceRoutes.updatePrice(id, priceId), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return data ? data : false;
        } catch (err) {
            throw new Error(err);
        }
    },
    updatePriceCurrency: async (id, info) => {
        try {
            const { data } = await fetch(PriceRoutes.updatePriceCurrency(id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return data ? data : false;
        } catch (err) {
            throw new Error(err);
        }
    },
    getExperiencesPriceGroupList: async (id) => {
        try {
            const { data } = await fetch(
                PriceRoutes.getExperiencesPriceGroupList(id),
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            return !data ? false : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    deletePrice: async (pgId, id) => {
      try {
          const { data } = await fetch(PriceRoutes.deletePrice(pgId, id), {
              method: Method.DELETE,
              authenticated: true,
          });

          return !data ? {} : data;
      } catch (err) {
          throw new Error(err);
      }
    },
    deletePriceGroup: async (id) => {
      try {
          const { data } = await fetch(PriceRoutes.deletePriceGroup(id), {
              method: Method.DELETE,
              authenticated: true,
          });

          return !data ? {} : data;
      } catch (err) {
          throw new Error(err);
      }
    },
};
